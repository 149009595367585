module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.aetnamedicaredirect.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-W2SW465","brandTokens":["AET"],"mapiBrandToken":"MED","siteName":"aetnamedicaredirect","alternateName":"aetnamedicaredirect.com","siteURL":"https://www.aetnamedicaredirect.com","defaultTitleTemplate":"","defaultPhone":"8552009401","phoneSymbol":"-","defaultPromoCode":"88487","smartyStreetsWebsiteKey":"","addressQualificationCode":"","vwoProjectID":"894940","datasetManagerAPIKey":"4f17ed19a8f942078e694522fbbee971","cobra":{"sitePath":"aetnamedicaredirect.com","buttonLink":"to={`https://enroll.clearlinkinsurance.com?site=amd`}","buttonText":"View Plans","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"aetnamedicaredirect","short_name":"aetnamedicaredirect","start_url":"/","background_color":"#7D3F98","theme_color":"#7D3F98","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b759ecac640298edd9cf91cdedbeea60"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
